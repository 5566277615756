/* eslint-disable react/prop-types */
import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { BaseLinkStyles } from 'aether-marketing';

/*
 * Text displayed above checkboxes for additional information
 *
 * Marketo type: "htmltext"
 *
 */

const Div = styled.div`
  color: ${(props) => props.theme.forms.text};
  font-size: 14px !important;
  text-align: left;
  margin-top: 24px;
  width: 100%;
  @media (min-width: 768px) {
    width: ${({ isEmail }) => (isEmail ? '500px' : '100%')};
  }
  > p {
    color: ${(props) => props.theme.forms.text} !important;
    margin-top: 8px;
  }
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }
`;

function FormLegalText({ ...props }) {
  useField({
    ...props
  });

  if (window.innerWidth >= 425) {
    if (document.querySelector('.error')) {
      document.querySelector('div[type="htmltext"]').style.marginTop = '24px';
    }
  }
  if (window.innerWidth <= 425) {
    if (document.querySelector('.error')) {
      document.querySelector('div[type="htmltext"]').style.marginTop = '24px';
    }
  }

  const { name, type, htmlText } = props;
  return (
    <div className="form-row pl-1">
      <div className="form-group col-12">
        <Div
          type={type === 'hidden' ? 'hidden' : type}
          className="formLegalText"
          name={name}
          dangerouslySetInnerHTML={{
            __html: htmlText
          }}
        />
      </div>
    </div>
  );
}

export default FormLegalText;
