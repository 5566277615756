import React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SectionStyles, Paragraph, BaseLink } from 'aether-marketing';
import eventsData from '../../../bff-data/events.json';
import css from '../css.json';

const runtime = typeof document === 'object';

const Card = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: ${(props) =>
    (props.theme.shadows && props.theme.shadows.card_shadow) ||
    css.shadows.card_shadow};
  padding: 32px;
  transition: ${(props) =>
    (props.theme.transitions && props.theme.transitions.all) ||
    css.transitions.all};
  &:hover {
    transform: translateY(-0.5rem);
    -webkit-transition: ${(props) =>
      (props.theme.transitions && props.theme.transitions.all) ||
      css.transitions.all};
    transition: ${(props) =>
      (props.theme.transitions && props.theme.transitions.all) ||
      css.transitions.all};
  }
`;

const CardInfo = styled.p`
  color: ${(props) =>
    (props.theme.colors && props.theme.colors.grey_90) || css.colors.grey_90};
  font-family: ${(props) =>
    (props.theme.fonts && props.theme.fonts.Inter) || css.fonts.Inter};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

const CardTitle = styled.h3`
  color: ${(props) =>
    (props.theme.colors && props.theme.colors.grey_90) || css.colors.grey_90};
`;

const CardLink = styled.a`
  width: 100%;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    -webkit-transform: translateY(-0.5rem);
    transform: translateY(-0.5rem);
    box-shadow: ${(props) =>
      (props.theme.shadows && props.theme.shadows.card_shadow) ||
      css.shadows.card_shadow};
    -webkit-box-shadow: ${(props) =>
      (props.theme.shadows && props.theme.shadows.card_shadow) ||
      css.shadows.card_shadow};
    -moz-box-shadow: ${(props) =>
      (props.theme.shadows && props.theme.shadows.card_shadow) ||
      css.shadows.card_shadow};
    -webkit-transition: ${(props) =>
      (props.theme.transitions && props.theme.transitions.all) ||
      css.transitions.all};
    transition: ${(props) =>
      (props.theme.transitions && props.theme.transitions.all) ||
      css.transitions.all};
    text-decoration: none;
  }
`;

const CardContentWrapper = styled.div`
  background: none;
  background-color: ${(props) =>
    (props.theme.colors && props.theme.colors.white) || css.colors.white};
  border-radius: ${(props) =>
    (props.theme.borderRadius && props.theme.borderRadius.medium) ||
    css.borderRadius.medium};
  width: 100%;
`;

const Tag = styled.div`
  border: 1px solid
    ${(props) =>
      (props.theme.colors && props.theme.colors.grey_30) || css.colors.grey_30};
  border-radius: ${(props) =>
    (props.theme.borderRadius && props.theme.borderRadius.small) ||
    css.borderRadius.small};
  color: ${(props) =>
    (props.theme.colors && props.theme.colors.grey_50) || css.colors.grey_50};
  float: right;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  padding: 0.5rem 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: 576px) {
    margin-right: 8px;
    float: left;
    white-space: normal;
  }
`;

const getIsStale = (date) => {
  const now = new Date();
  const isFresh = Boolean(new Date(`${date}`) > now);

  return (!isFresh && ' d-none') || '';
};

class EventCard extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;

    this.state = {
      data
    };
  }

  render() {
    const { data } = this.state;
    if (data.link.startsWith('https://www.postman.com')) {
      data.type = 'same-tab';
      data.checkType = 'same-tab';
      data.rel = '';
      data.gaCategory = 'events-homepage';
    } else {
      data.type = '_blank';
      data.checkType = 'new-tab-external-nofollow';
      data.rel = 'noopener noreferrer';
      data.gaCategory = 'events-homepage';
    }
    return (
      <div className={`col-12 col-lg-10 h-100 ${getIsStale(data.date)}`}>
        <Card data-item className="m-3 secondary-border">
          <CardLink
            href={data.link}
            target={data.type}
            rel={data.rel}
            gaLabel={data.slug}
            gaCategory={data.gaCategory}
            data-url
          >
            <div className="row">
              <CardContentWrapper className="col-12">
                <Tag className="mb-2" data-location>
                  {data.location}
                </Tag>
                <CardInfo className="mb-4" data-location>
                  {`${data.eventDate} ${data.time}`}
                </CardInfo>

                <CardTitle className="h4 mb-2" data-title>
                  {data.title}
                </CardTitle>
                <Paragraph>{data.description}</Paragraph>
                <div>
                  <BaseLink
                    as="span"
                    src={data.link}
                    target={data.checkType}
                    rel={data.rel}
                    linkType="arrowLink"
                    gaLabel={data.slug}
                    gaCategory={data.gaCategory}
                  >
                    Learn more
                  </BaseLink>
                </div>
              </CardContentWrapper>
            </div>
          </CardLink>
        </Card>
      </div>
    );
  }
}

const eventDynamic = (data) => {
  let hasFreshEvent = true;

  eventsData.forEach((event) => {
    if (!hasFreshEvent && !getIsStale(event.date, event.year)) {
      hasFreshEvent = true;
    }
  });

  if (runtime) {
    window.pmt('bff', [
      'events',
      (d) => {
        const container = document.body.querySelector('[data-items]');
        const items = JSON.parse(d);

        container.innerHTML = '';

        const runtimeEvents = (
          <>
            {items.map((item) => {
              return (
                <EventCard key={`${item.date}-${item.title}`} data={item} />
              );
            })}
          </>
        );

        container.innerHTML =
          ReactDOMServer.renderToStaticMarkup(runtimeEvents);
      },
      true
    ]);
  }

  return (
    <SectionStyles
      style={{ backgroundColor: data.backgroundColor }}
      data-type="event"
    >
      <div className="container">
        <div className="row">
          <div
            className={
              `${data.textAlign}`
                ? `col-10 offset-1 ${data.textAlign} `
                : `col-10 offset-1 text-left`
            }
          >
            <h2 className="mb-2" data-title>
              {data.title}
            </h2>
            <Paragraph
              data-subtitle
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html:
                  (Array.isArray(data.subtitle) && data.subtitle.shift()) ||
                  data.subtitle
              }}
            />
          </div>
        </div>
        <div
          className="row justify-content-center d-flex align-items-stretch"
          data-items
        >
          {hasFreshEvent ? (
            <>
              {eventsData.map((item) => {
                return (
                  <EventCard key={`${item.date}-${item.title}`} data={item} />
                );
              })}
            </>
          ) : (
            <Paragraph className="subtitle">
              There are no upcoming events at this time. Check back soon!
            </Paragraph>
          )}
        </div>
      </div>
    </SectionStyles>
  );
};

EventCard.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    date: PropTypes.string,
    url: PropTypes.string,
    location: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired
};

export default eventDynamic;
