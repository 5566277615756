import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BaseButton, UnorderedListStyles } from 'aether-marketing';
import SingleInputForm from './forms/layout/SingleInputForm';

const SectionStyles = styled.section`
  padding-top: 60px;
  padding-bottom: 60px;
  background: ${(props) => props.backgroundColor};
  color: white;

  a {
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
  }
  a:hover {
    color: #ff6c37;
    text-decoration: none;
    border-bottom: 1px solid #ff6c37;
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
    padding-left: 0;
  }
  ul li {
    color: white;
  }
  @media (min-width: 769px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .gradientText {
    background-image: linear-gradient(to right, #ff6c37, #c64dda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }
  .titleStyles {
    font-size: 48px;
    line-height: 1;
    margin: 0 0 24px 0;
  }
  form {
    color: white !important;
  }
`;

function relStringGenerator(target) {
  if (target === 'new-tab-postman' || target === 'external-postman') {
    return 'noopener';
  }
  if (target === 'new-tab-external-company' || target === 'external-other') {
    return 'noopener noreferrer';
  }
  if (
    target === 'new-tab-external-nofollow' ||
    target === 'external-nofollow'
  ) {
    return 'noopener noreferrer nofollow';
  }

  return null;
}
// Helper function for target attribute in link or button
function targetStringGenerator(target) {
  const newTabs = [
    'new-tab-postman',
    'new-tab-postman',
    'new-tab-external-company',
    'external-other',
    'new-tab-external-nofollow',
    'new-tab-external-nofollow'
  ];

  for (let i = 0; i < newTabs.length; i += 1) {
    if (newTabs[i] === target) {
      return '_blank';
    }
    if (target === 'same-tab') {
      return '_self';
    }
  }

  return '_self';
}
function PostconCallOut({ title, body, link, backgroundColor, form, eyebrow }) {
  const anchorTag = title
    .replace(/\s+/g, '-')
    .replace(/\?/g, '')
    .replace(/\//g, '')
    .toLowerCase();
  return (
    <SectionStyles id={anchorTag} backgroundColor={backgroundColor}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {eyebrow && <p className="gradientText mb-0 h3">{eyebrow}</p>}
            {/* <p className={"h3 gradientText mb-0">Join the waitlist</p> */}
            {title && <h2 className="">{title}</h2>}
            {body && (
              <div className="">
                <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
              </div>
            )}
            {form && (
              <div className="ml-lg-0">
                <SingleInputForm form={form} />
              </div>
            )}
            {link && (
              <div className="mt-3">
                <BaseButton
                  buttonType={link.buttonType}
                  src={link.src}
                  href={link.src}
                  id={link.id}
                  rel={relStringGenerator(link.target)}
                  target={targetStringGenerator(link.target)}
                  as="a"
                  aria-label={link.ariaLabel}
                >
                  {link.text}
                </BaseButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </SectionStyles>
  );
}
PostconCallOut.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  backgroundColor: PropTypes.string,
  form: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    buttonText: PropTypes.string,
    gaCategory: PropTypes.string,
    gaLabel: PropTypes.string
  }),
  link: PropTypes.shape({
    buttonType: PropTypes.string,
    src: PropTypes.string,
    id: PropTypes.string,
    target: PropTypes.string,
    ariaLabel: PropTypes.string,
    text: PropTypes.string
  }),
  eyebrow: PropTypes.string
};

PostconCallOut.defaultProps = {
  title: null,
  body: null,
  link: null,
  backgroundColor: null,
  form: null,
  eyebrow: null
};

export default PostconCallOut;
