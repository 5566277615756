import React from 'react';
import styled from 'styled-components';
import { PrimaryButtonStyles } from '../../subcomponents/PrimaryButton';

export const ButtonStyled = styled.button`
  ${PrimaryButtonStyles.componentStyle.rules}
  width: 100%;
`;

// Google Analytics Trigger
const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

function FormButton(data) {
  const { submitButton } = data;
  const { event, eventProp } = submitButton;
  const hasButtonText = submitButton ? submitButton.text : 'Submit';
  return (
    <div>
      <ButtonStyled
        id={submitButton.id || null}
        className="mt-2 mb-2"
        disabled={submitButton.disabled || false}
        type="submit"
        data-event={event}
        data-event-prop={eventProp}
        onClick={() => {
          triggerGA(
            submitButton.gaCategory && submitButton.gaCategory,
            submitButton.gaLabel && submitButton.gaLabel
          );
        }}
      >
        {hasButtonText}
      </ButtonStyled>
    </div>
  );
}

export default FormButton;
