import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
/* eslint-enable */

import axios from 'axios';
import FormContainer from './FormContainer';
import MarketoForm from './MarketoForm';
import FormLegalAdditionalText from '../components/FormLegalAdditionalText';
import FormButton from '../components/FormButton';
import handleErrorValidation from '../helpers/handleErrorValidation';
import {
  FormTitle,
  FormSubtitle,
  FormFailText
} from '../helpers/handleFormCopy';
import FormFields from '../helpers/handleFormFields';
import {
  selectFormById,
  setInitialValues,
  updateInitialTypes
} from '../helpers/handleFormLoad';
import handleFormSubmission from '../helpers/handleFormSubmission';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

function FormV7(data) {
  const { form } = data;
  const {
    title,
    subtitle,
    marketoForm,
    submitButton,
    addOnLegalText,
    addOnCheckboxLabels,
    confirmation
  } = form;
  const { id } = marketoForm;

  const [useFormData, setFormData] = useState([]);
  const [throwFailMessage, setFailMessage] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const initialValues = {};
  const fields = useFormData;
  setInitialValues(initialValues, fields);
  useEffect(() => {
    /* 1. get form data with /mkapi/forms.json endpoint
     * 2. if response fails or id is not found, use cached version from /bff-data/forms.json as fallback
     * 3. if cached response fails or id is not found, render message to user to avoid errors
     */

    const fetchForms = async () => {
      try {
        /* eslint-disable global-require, import/no-dynamic-require */
        const response = require(`../../../../../bff-data/forms.json`);
        const selectedForm = selectFormById(response, id);
        const hasFormData = selectedForm.length !== 0;
        if (hasFormData) {
          setFormData(selectedForm);
          setFailMessage(false);
        } else {
          throw new Error();
        }
      } catch (error) {
        try {
          /* eslint-disable global-require, import/no-dynamic-require */
          /* eslint-enable */
          const { data: fallbackResponse } = await axios.get(
            `/mkapi/forms.json?t=${new Date().getTime()}`
          );
          const selectedForm = selectFormById(fallbackResponse, id);
          const hasFormData = selectedForm.length !== 0;
          setFailMessage(!hasFormData);
          setFormData(selectedForm);
        } catch (err) {
          setFailMessage(true);
        }
      }
    };

    fetchForms();
  }, [id]);

  return (
    <>
      {confirmation && formSubmitted ? (
        <div>
          <h3>{confirmation.title}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: confirmation.body.join('') }}
          />
          <a href="/">← Return to Postman</a>
        </div>
      ) : (
        <FormContainer data-test="isFormLoading" id="postcon-form">
          {fields.length > 0 ? (
            <>
              <FormTitle title={title} />
              <FormSubtitle subtitle={subtitle} />
              <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                  handleFormSubmission(
                    id,
                    data,
                    values,
                    setSubmitting,
                    setFormSubmitted
                  );
                }}
                validationSchema={handleErrorValidation(fields)}
                enableReinitialize
              >
                <Form id={`form-${id}`} noValidate>
                  {fields.map((field) => {
                    // properties coming from marketo
                    const {
                      Id,
                      Datatype,
                      DefaultValue,
                      Htmltext,
                      IsRequired,
                      InputLabel,
                      Maxlength,
                      Name,
                      PicklistValues,
                      PlaceholderText,
                      VisibleRows,
                      VisibilityRule
                    } = field;
                    const types = updateInitialTypes(Datatype);

                    return (
                      // set attributes and custom props
                      <FormFields
                        key={pmUuid()}
                        inputlabel={InputLabel || ''}
                        label={PlaceholderText || Name}
                        name={Name || ''}
                        mkId={Id || ''}
                        required={IsRequired || false}
                        max={Maxlength || ''}
                        type={types}
                        // Dropdowns and Checkboxes
                        dropdownDefaultValue={DefaultValue || ''}
                        selectValues={PicklistValues || ''}
                        VisibilityRule={VisibilityRule || ''}
                        // Text Area
                        rows={VisibleRows || ''}
                        // Legal Text
                        htmlText={Htmltext || ''}
                        // Custom Props
                        addOnCheckboxLabels={
                          addOnCheckboxLabels &&
                          field.Datatype === 'single_checkbox'
                            ? addOnCheckboxLabels
                            : null
                        }
                      />
                    );
                  })}
                  <FormButton submitButton={submitButton} />
                  {addOnLegalText && (
                    <FormLegalAdditionalText addOnLegalText={addOnLegalText} />
                  )}
                </Form>
              </Formik>
            </>
          ) : null}
        </FormContainer>
      )}
      <MarketoForm data={marketoForm} />
      {throwFailMessage ? (
        <FormContainer>
          <FormFailText />
        </FormContainer>
      ) : null}
    </>
  );
}

export default FormV7;
