import React from 'react';
import styled from 'styled-components';
import { Divider, Paragraph, BaseButton, Secondary } from 'aether-marketing';
import ModalComponent from '../v5_modal-overlays/ModalComponent';

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: ${(props) => props.paddingTop || `60px`};
  padding-bottom: ${(props) => props.paddingBottom || `60px`};
  .vide-btn {
    ${Secondary.componentStyle.rules}
  }
  .video-btn-white {
    color: white !important;
    border: 1px solid white;
    border-radius: ${(props) => props.theme.borderRadius.small};
  }
`;
const ParentTitleTemp = styled.p`
  color: ${(props) => props.theme.colors.grey_90};
  font-weight: 600;
`;

const ParentTitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const ParentSubtitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const heroWithModalAndImage = (data) => (
  <>
    <Section
      style={{
        backgroundImage: data.background,
        backgroundColor: data.backgroundColor,
        paddingTop: data.paddingTop,
        paddingBottom: data.paddingBottom
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6 text-lg-left text-center align-self-center order-2 order-lg-1 mt-5 mt-sm-0">
            {data.parentTitle && (
              <div className="row">
                <div className="col-12">
                  <ParentTitleText>{data.parentTitle}</ParentTitleText>
                </div>
              </div>
            )}
            {data.parentTitleTemp && (
              <div className="row">
                <div className="col-12">
                  <ParentTitleTemp>{data.parentTitleTemp}</ParentTitleTemp>
                </div>
              </div>
            )}
            <h1 className={`mb-4 ${data.className}`}>{data.titles}</h1>
            {data.parentSubtitle && (
              <div className="row">
                <div className="col-12">
                  <ParentSubtitleText>{data.parentSubtitle}</ParentSubtitleText>
                </div>
              </div>
            )}
            <Paragraph className={`subtitle ${data.className}`}>
              {data.body}
            </Paragraph>
            {data.cta && (
              <BaseButton
                as="a"
                buttonType={data.cta.buttonType}
                src={data.cta.src}
                target={data.cta.target}
                className={data.cta.className}
              >
                {data.cta.text}
              </BaseButton>
            )}
            {data.modal && (
              <ModalComponent
                className={` ${data.button.className || 'video-btn'}`}
                modal={data.modal}
                button={data.button}
                isSecondary={data.button.isSecondary}
              />
            )}
            {data.links && (
              <BaseButton
                buttonType="secondary"
                className={`mt-3 ${data.className}`}
                as="a"
                src={data.links.src}
                target={data.links.target}
                dataTest={data.links.target}
              >
                {data.links.text}
              </BaseButton>
            )}
          </div>
          <div className="col-sm-12 col-lg-6 align-self-center order-1 order-lg-2 text-center">
            {data.media ? (
              <img
                className="img-fluid"
                src={data.media[0].image}
                alt={data.media[0].alt}
                fetchpriority="high"
                loading="eager"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </Section>
    <Divider fullWidth />
    <span data-sticky={data.stickyAttribute || null} />
  </>
);

export default heroWithModalAndImage;
