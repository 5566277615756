/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { Field, useField } from 'formik';
import styled from 'styled-components';
import FormLabel, { LabelStyled } from './FormLabel';
import FormError from './FormError';

const RadioLabel = styled.label`
  ${LabelStyled.componentStyle.rules};
  color: ${(props) => props.theme.forms.text};
  margin-left: 24px;
  width: auto !important;

  &:hover {
    cursor: pointer;
  }

  .form-check-input {
    margin-top: 0.6rem;
    margin-left: -20px;
  }
`;

const RadioInput = styled.input`
  &:hover {
    cursor: pointer;
  }
`;

function FormRadio(props) {
  const [meta] = useField({ ...props });
  const { name, type, selectValues, inputlabel, required, mkId } = props;
  const renderRadioButtons = useCallback(() => {
    return selectValues.map((item, idx) => (
      <div key={item.value}>
        <RadioLabel
          className="form-check-label"
          htmlFor={`mktoRadio_${mkId}_${idx}`}
          inputlabel={inputlabel}
        >
          <Field
            as={RadioInput}
            id={`mktoRadio_${mkId}_${idx}`}
            role="group"
            type={type === 'hidden' ? 'hidden' : 'radio'}
            className="form-check-input"
            name={name}
            aria-labelledby={name}
            value={item.value}
            required={required}
          />
          {item.label}
        </RadioLabel>
      </div>
    ));
  }, [selectValues, mkId, inputlabel, type, name, required]);

  return (
    <div className="form-row">
      <div className="form-group col-12">
        {FormLabel(props)}
        {renderRadioButtons()}
      </div>
      <div className="col-12">
        {meta.touched && meta.error ? (
          <FormError className="error">{meta.error}</FormError>
        ) : null}
      </div>
    </div>
  );
}

export default FormRadio;
