/* eslint-disable react/function-component-definition */
import React from 'react';

/*
 * User-defined form title, subtitle and hardcoded fail text
 * "form": {
 *    "title": "",
 *    "subtitle": [""],
 *   }
 *
 */
export const FormTitle = (data) => {
  const { title } = data;
  return title && title !== undefined ? (
    <h3 className="text-left">{title}</h3>
  ) : null;
};

export const FormSubtitle = (data) => {
  const { subtitle } = data;
  return subtitle && subtitle.length > 0 ? (
    <div
      className="text-left mb-3"
      dangerouslySetInnerHTML={{
        __html: subtitle.join('')
      }}
    />
  ) : null;
};
export const FormFailText = () => {
  return (
    <p className="subtitle" style={{ color: '#969696' }}>
      Sorry! Something went wrong loading the form. Please refresh your browser,
      disable ad-blockers or try again later.
    </p>
  );
};
